import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

$(function () {
  gsap.from('.feature-card .inner', {
    scrollTrigger: {
      trigger: '.feature-card',
      start: 'top 75%',
      end: 'bottom 65%',
      scrub: true,
    },
    stagger: 0.33,
    opacity: 0,
    y: 30,
  })
})
